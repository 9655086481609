var hamburger = document.getElementById('js-hamburger'),
  menu = document.getElementById('js-navigation-menu')

function toggle_menu() {
  hamburger.classList.toggle('is-active')
  menu.classList.toggle('is-active')
}

var notification_button = document.querySelector('.notification-banner__button');
if (notification_button) {
  if (!readCookie('hide-notification-2020')) {
    document.querySelector(".notification-banner").classList.add("is-active");

    notification_button.addEventListener('click', function () {
      document.querySelector(".notification-banner").classList.remove("is-active");
      writeCookie('hide-notification-2020', 1, 1);
    });
  }
}

$(document).ready(function () {
  $('.gallery').modaal({
    type: 'image'
  });
  $('.map').height($('.basic-info').outerHeight());
});

function writeCookie(name, value, days) {
  var date, expires;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var i, c, ca, nameEQ = name + "=";
  ca = document.cookie.split(';');
  for (i = 0; i < ca.length; i++) {
    c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return '';
}

